export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const NEW_PRODUCT = "NEW_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";

export const FETCH_BRANDS = "FETCH_BRANDS";
export const NEW_BRAND = "NEW_BRAND";
export const DELETE_BRAND = "DELETE_BRAND";
export const UPDATE_BRAND = "UPDATE_BRAND";

export const FETCH_SALES = "FETCH_SALES";
export const NEW_SALE = "NEW_SALE";
export const DELETE_SALE = "DELETE_SALE";

export const FETCH_STOCKS = "FETCH_STOCKS";
export const NEW_STOCK = "NEW_STOCK";
export const DELETE_STOCK = "DELETE_STOCK";

export const FETCH_DAMAGED = "FETCH_DAMAGED";
export const NEW_DAMAGED = "NEW_DAMAGED";
export const DELETE_DAMAGED = "DELETE_DAMAGED";

export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const NEW_CATEGORY = "NEW_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";

export const FETCH_ERROR = "FETCH_ERROR";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const POST_SUCCESS = "POST_SUCCESS";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";

//export const SERVERNAME = "http://manduloapi.test/api/";
export const SERVERNAME = "https://mandulochemical.com/mandulo/api/"; //http://manduloapi.test/api/
