import React, { useState, useEffect } from "react";
import Navbar from "./NavBar.js";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import GridContainer from "./components/Grid/GridContainer.js";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Swal from "sweetalert2";
import { connect, useSelector, useDispatch } from "react-redux";
import NavButtons from "./NavButtons";
//import InputAdornment from "@material-ui/material/InputAdornment";
import InputAdornment from "@material-ui/core/InputAdornment";
import clsx from "clsx";

import { newSale } from "../actions/saleActions";
import { Typography } from "@material-ui/core";
import GridItem from "./components/Grid/GridItem.js";
import { COLORS } from "./colors";

export default function POS(props) {
  const dispatch = useDispatch();
  const [myRows, setRows] = useState([]);
  const [currentBrand, setCurrentBrand] = useState();

  const TAX_RATE = 0;
  let TRANSPORT_RATE;
  if (props.user === 6) TRANSPORT_RATE = 0.4;
  else TRANSPORT_RATE = 0.0;

  /* const useStyles = makeStyles({
      table: {
        minWidth: 100,
      },
    }); */

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150,
    },
    table: {
      minWidth: 100,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      paddingLeft: "10%",
      paddingRight: "10%",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    resize: { fontSize: 10 },
  }));

  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }

  function priceRow(qty, unit) {
    return qty * unit;
  }

  function createRow(desc, qty, unit) {
    const price = priceRow(qty, unit);
    return { desc, qty, unit, price };
  }

  function subtotal(items) {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
  }

  const rows = [
    createRow("Paperclipsee  (Box)", 100, 1.15),
    createRow("Paper (Case)", 10, 45.99),
    createRow("Waste Basket", 2, 17.99),
  ];

  const invoiceSubtotal = subtotal(myRows);
  const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  //const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  const transportTaxes = TRANSPORT_RATE * invoiceSubtotal;
  const invoiceTotal = invoiceTaxes + transportTaxes + invoiceSubtotal;

  const classes = useStyles();

  const [state, setState] = React.useState({
    category: "",
    product: "",
    brand: "",
    quantity: 1,
    unit_price: "",
    mybrands: [],
    chosenbrand: {},
    myprice: "",
  });

  const [values, setValues] = React.useState({
    name: "",
    email: "",
    invoice_num: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [myerror, setErrors] = useState();
  const user = useSelector((state) => state.user.user);
  const errors = useSelector((state) => state.error.errors);
  const success = useSelector((state) => state.error.success);
  const products = useSelector((state) => state.products.items);
  const categories = useSelector((state) => state.categories.items);

  const [open, setOpen] = React.useState(false);
  const [x, setX] = React.useState(1);

  const handleOpen = () => {
    setOpen(true);
    setErrors("");
    setState({
      ...state,
      ["category"]: "",
      ["product"]: "",
      ["brand"]: "",
      [" myprice"]: "",
      ["mybrands"]: [],
      ["chosenbrand"]: {},
      ["productID"]: "",
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addItem = () => {
    console.log("rows");
    console.log(myRows.length);
    var xrows = myRows;
    if (
      state.category != "" &&
      state.productID != "" &&
      state.brand != "" &&
      state.quantity != ""
    ) {
      const cbrand = state.chosenbrand;
      const price = priceRow(state.quantity, state.myprice);
      xrows.push({
        product_id: state.productID,
        id: cbrand.id,
        item: state.brand,
        quantity: state.quantity,
        unit: state.myprice,
        price: price,
      });

      setRows(xrows);
      var d = x + 1;
      setX(d);
      setOpen(false);

      //clear state

      setState({
        ...state,
        ["category"]: "",
        ["product"]: "",
        ["brand"]: "",
        ["myprice"]: "",
        ["mybrands"]: [],
        ["chosenbrand"]: {},
        ["productID"]: "",
      });
    } else setErrors("All fields are required");
  };

  const handleChanged = (event) => {
    const name = event.target.name;
    const nameid = event.target.value;
    setState({
      ...state,
      [name]: event.target.value,
    });
    if (event.target.name == "product") {
      const myproduct = products.find((p) => p.name == nameid);
      console.log(myproduct);
      setState({
        ...state,
        ["productID"]: myproduct.id,
        ["mybrands"]: myproduct.brands,
        [name]: event.target.value,
      });

      console.log(state.mybrands);
    }

    if (event.target.name == "categoryxx") {
      const myproduct = products.find((p) => p.name == nameid);
      console.log(myproduct);
      setState({
        ...state,
        ["mybrands"]: myproduct.brands,
        [name]: event.target.value,
      });

      console.log(state.mybrands);
    }

    if (event.target.name == "brand") {
      let p;
      const thebrand = state.mybrands.find((b) => b.name == nameid);

      console.log("ID");
      console.log(user.user.id);
      /*  if (user.user.id === 6) p = thebrand.price_sa; */
      p = thebrand.price_usd;

      setState({
        ...state,
        ["chosenbrand"]: thebrand,
        ["myprice"]: p,
        [name]: event.target.value,
      });

      console.log(state.chosenbrand);
    }
  };

  const handleCurrentBrand = (brand) => {
    setCurrentBrand(brand.price);
  };
  let sale = {};
  const handleSale = () => {
    var items = [];
    myRows.map((row) => {
      const item = {
        product_id: row.product_id,
        brand_id: row.id,
        quantity: row.quantity,
        unit_cost_usd: row.unit,
        total_cost_usd: row.price,
      };
      items.push(item);
    });
    sale = {
      products: items,
      total_price: invoiceTotal,
      trans_percentage: TRANSPORT_RATE,
      customer: values.name,
      customer_email: values.email,
      invoice_number: values.invoice_num,
    };

    console.log(sale);
    // dispatch(newSale(sale));

    setRows([]);
    setValues({
      ...values,
      ["name"]: "",
      ["email"]: "",
      ["invoice_num"]: "",
    });
  };

  const handleSaleClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Make Sale!",
    }).then((result) => {
      if (result.value) {
        handleSale();
        dispatch(newSale(sale)).then((result) => {
          console.log("my result " + result);
          if (result != "success") {
            //error
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: { errors },
            }).then(
              //
              console.log("helalsnn")
            );
          } else {
            //success
            Swal.fire("Successful!", "Sale has been added.", "success");
          }
        });
      }
    });
  };

  const handleCancelSale = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "It will clear all added items!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Clear Sale!",
    }).then((result) => {
      if (result.value) {
        console.log("cancelled");
        setRows([]);
        setValues({
          ...values,
          ["name"]: "",
          ["email"]: "",
          ["invoice_num"]: "",
        });
      }
    });
  };

  const add = () => {
    let q = state.quantity;
    q = q + 1;
    setState({
      ...state,
      ["quantity"]: q,
    });
  };

  const subtract = () => {
    let q = state.quantity;
    q = q - 1;
    if (q < 0) q = 0;
    setState({
      ...state,
      ["quantity"]: q,
    });
  };

  //update quantiy

  //const [data, setData] = useState(initialState);

  const updateState = (id, type) => {
    const newState = myRows.map((obj) => {
      let quant;
      let theprice;
      // 👇️ if id equals 2, update country property
      if (obj.id === id) {
        if (type === "add") {
          quant = obj.quantity + 1;
          theprice = obj.unit * quant;
        } else {
          if (obj.quantity > 1) {
            quant = obj.quantity - 1;
            theprice = obj.unit * quant;
          } else {
            //delete

            // var array = [...myRows]; // make a separate copy of the array
            // var index = array.indexOf(obj.id);
            // if (index !== -1) {
            //   array.splice(index, 1);
            //   setRows(array);
            // }
            /* setRows((current) =>
              current.filter((row) => {
                // 👇️ remove object that has id equal to 2
                return row.id !== obj.id;
              })
            ); */
            return obj;
          }
        }
        return { ...obj, quantity: quant, price: theprice };
      }

      // 👇️ otherwise return object as is
      return obj;
    });

    setRows(newState);
  };

  return (
    <React.Fragment>
      <NavButtons view="1" />
      <div className={classes.container}>
        <br></br>
        <Button
          variant="contained"
          style={{ backgroundColor: "#b5df61", paddingLeft: "5px" }}
          onClick={handleOpen}
        >
          Add Sale Item <AddIcon />
        </Button>
        <TableContainer
          component={Paper}
          style={{ marginTop: "50px", paddingLeft: "5px" }}
        >
          <Table className={classes.table} aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={3}
                  style={{
                    backgroundColor: COLORS.primary,
                    color: COLORS.white,
                  }}
                >
                  Details
                </TableCell>
                <TableCell
                  align="right"
                  style={{ backgroundColor: COLORS.secondary }}
                >
                  Price
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="right">Qty.</TableCell>
                <TableCell align="right">Unit</TableCell>
                <TableCell align="right">Sum</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myRows.length < 1 ? (
                <TableRow>
                  <Typography variant="h5" style={{ color: "red" }}>
                    No added sale items
                  </Typography>{" "}
                </TableRow>
              ) : (
                <React.Fragment>
                  {myRows.map((row) => (
                    <TableRow key={row.item}>
                      <TableCell>{row.item}</TableCell>
                      <TableCell align="right">
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <IconButton
                              size="small"
                              aria-label="remove"
                              color="primary"
                              onClick={() => {
                                updateState(row.id, "subtract");
                              }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </Grid>
                          <Grid item>{row.quantity}</Grid>
                          <Grid item>
                            <IconButton
                              aria-label="add"
                              color="secondary"
                              onClick={() => {
                                updateState(row.id, "add");
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="right">
                        $ {ccyFormat(row.unit)}
                      </TableCell>
                      <TableCell align="right">
                        $ {ccyFormat(row.price)}
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow>
                    <TableCell rowSpan={3}>
                      {/*     <div>
                        <GridContainer style={{ marginTop: 0.5 }}>
                          <GridItem sm={6}></GridItem>
                          <GridItem
                            sm={6}
                            style={{ border: 1 }}
                            justify="center"
                            align="right"
                          >
                            <Paper style={{ padding: 10 }}>
                              {" "}
                              <form>
                                <div style={{ marginRight: "20%" }}>
                                  <TextField
                                    label="Customer Name"
                                    value={values.name}
                                    onChange={handleChange("name")}
                                  />
                                </div>
                                <div style={{ marginRight: "20%" }}>
                                  <TextField
                                    label="Customer Email"
                                    value={values.email}
                                    onChange={handleChange("email")}
                      
                                  />
                                </div>
                                <br></br>
                                <div style={{ marginRight: "19%" }}>
                                  <Button
                                    style={{
                                      paddingTop: "3px",
                                      width: "90px",
                                      backgroundColor: COLORS.primary,
                                      color: COLORS.white,
                                    }}
                                    variant="contained"
                                    onClick={handleSaleClick}
                                  >
                                    Sale
                                  </Button>

                                  <Button
                                    style={{
                                      paddingTop: "3px",
                                      marginLeft: "10px",
                                      width: "90px",
                                      //  backgroundColor: "#ff4d4d",
                                    }}
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleCancelSale}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </form>
                            </Paper>
                          </GridItem>
                        </GridContainer>
                      </div> */}
                      {/*  <div>
                        <TextField
                          label="Customer Name"
                          value={values.name}
                          onChange={handleChange("name")}
                        />
                      </div>
                      <div>
                        <TextField
                          label="Customer Email"
                          value={values.name}
                          onChange={handleChange("name")}
                          InputProps={{
                            classes: {
                              input: classes.resize,
                            },
                          }}
                        />
                      </div>
                      <br></br>
                      <div>
                        <Button
                          style={{ paddingTop: "3px", width: "90px" }}
                          variant="contained"
                          color="primary"
                          onClick={handleSaleClick}
                        >
                          Sale
                        </Button>
                      </div>
                      <div style={{ paddingTop: "3px", width: "50%" }}>
                        <Button
                          style={{ paddingTop: "3px", width: "90px" }}
                          variant="contained"
                          color="secondary"
                          onClick={handleCancelSale}
                        >
                          Cancel
                        </Button>
                      </div> */}
                    </TableCell>
                    <TableCell colSpan={2}>Subtotal</TableCell>
                    <TableCell align="right">
                      {ccyFormat(invoiceSubtotal)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tax</TableCell>
                    <TableCell align="right">{`${(TAX_RATE * 100).toFixed(
                      0
                    )} %`}</TableCell>
                    <TableCell align="right">
                      {ccyFormat(invoiceTaxes)}
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell>Transport</TableCell>
                    <TableCell align="right">{`${(TRANSPORT_RATE * 100).toFixed(
                      0
                    )} %`}</TableCell>
                    <TableCell align="right">
                      {ccyFormat(transportTaxes)}
                    </TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell colSpan={2} align="right">
                      Total
                    </TableCell>
                    <TableCell align="right">
                      {ccyFormat(invoiceTotal)}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {myRows.length > 0 && (
          <div>
            <GridContainer style={{ marginTop: 0.5 }}>
              <GridItem sm={6}></GridItem>
              <GridItem
                sm={6}
                style={{ border: 1 }}
                justify="center"
                align="right"
              >
                <Paper style={{ padding: 10 }}>
                  {" "}
                  <form>
                    <div style={{ marginRight: "20%" }}>
                      <TextField
                        label="Invoice Number"
                        value={values.invoice_num}
                        onChange={handleChange("invoice_num")}
                      />
                    </div>

                    <div style={{ marginRight: "20%" }}>
                      <TextField
                        label="Customer Name"
                        value={values.name}
                        onChange={handleChange("name")}
                      />
                    </div>
                    <div style={{ marginRight: "20%" }}>
                      <TextField
                        label="Customer Email"
                        value={values.email}
                        onChange={handleChange("email")}
                        type="email"
                        /* InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }} */
                      />
                      {/* <input
                        type="email"
                        value={values.email}
                        onChange={handleChange("email")}
                      ></input> */}
                    </div>
                    <br></br>
                    <div style={{ marginRight: "19%" }}>
                      <Button
                        style={{
                          paddingTop: "3px",
                          width: "90px",
                          backgroundColor: COLORS.primary,
                          color: COLORS.white,
                        }}
                        variant="contained"
                        onClick={handleSaleClick}
                      >
                        Sale
                      </Button>

                      <Button
                        style={{
                          paddingTop: "3px",
                          marginLeft: "10px",
                          width: "90px",
                          //  backgroundColor: "#ff4d4d",
                        }}
                        color="secondary"
                        variant="contained"
                        onClick={handleCancelSale}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Paper>
              </GridItem>
            </GridContainer>
          </div>
        )}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <form style={{ width: "250px" }}>
                {myerror && <span style={{ color: "red" }}>{myerror}</span>}
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="category">Category</InputLabel>
                  <Select
                    native
                    value={state.category}
                    onChange={handleChanged}
                    inputProps={{
                      name: "category",
                      id: "category",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {categories.map((category) => (
                      <option value={category.name}>{category.name}</option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="product">Product type</InputLabel>
                  <Select
                    native
                    value={state.product}
                    onChange={handleChanged}
                    inputProps={{
                      name: "product",
                      id: "product",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {products.map((product) => (
                      <option value={product.name}>{product.name}</option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="brand">Brand</InputLabel>
                  <Select
                    native
                    value={state.brand}
                    onChange={handleChanged}
                    inputProps={{
                      name: "brand",
                      id: "brand",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {state.mybrands.map((brand) => (
                      <option
                        value={brand.name}
                        /*  onChange={() => handleCurrentBrand(brand)} */
                        //  disabled={brand.quantity == 0 ? true : null}
                      >
                        {brand.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Unit Cost"
                    value={state.myprice}
                    onChange={handleChanged}
                    style={{ width: "100px" }}
                    className={clsx(classes.margin, classes.textField)}
                    inputProps={{
                      name: "myprice",
                      id: "myprice",
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    type="number"
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <IconButton
                        size="small"
                        aria-label="remove"
                        color="primary"
                        onClick={subtract}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <TextField
                        value={state.quantity}
                        onChange={handleChanged}
                        style={{ width: "60px" }}
                        inputProps={{
                          name: "quantity",
                          id: "quantity",
                        }}
                        type="number"
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={add}
                        aria-label="add"
                        color="secondary"
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </FormControl>

                <br></br>

                <Button
                  style={{
                    width: "90px",
                    backgroundColor: COLORS.primary,
                    color: COLORS.white,
                  }}
                  variant="contained"
                  color="primary"
                  onClick={addItem}
                >
                  Add
                </Button>
                <span style={{ paddingLeft: "10px" }}></span>
                <Button
                  style={{ paddingLeft: "10px", width: "90px" }}
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </form>
            </div>
          </Fade>
        </Modal>
      </div>
    </React.Fragment>
  );
}
