import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { COLORS } from "./colors";
import Button from "@material-ui/core/Button";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import backgroundImg from "../assets/img/bg2.jpg";
import { Provider, useSelector } from "react-redux";
import ProductManager from "./ProductManager";
import PointOfSale from "../assets/img/point_of_sale.svg";
import Inventory from "../assets/img/inventory.svg";
import TimelineIcon from "@material-ui/icons/Timeline";
import ReceiptIcon from "@material-ui/icons/Receipt";
//import InventoryIcon from "@mui/icons-material/Inventory";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { SvgIcon } from "@material-ui/core";
import Navbar from "./NavBar.js";
import logo from "../assets/img/mandulologo2.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function PointOfSaleIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M17,2H7C5.9,2,5,2.9,5,4v2c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V4C19,2.9,18.1,2,17,2z M17,6H7V4h10V6z M20,22H4 c-1.1,0-2-0.9-2-2v-1h20v1C22,21.1,21.1,22,20,22z M18.53,10.19C18.21,9.47,17.49,9,16.7,9H7.3c-0.79,0-1.51,0.47-1.83,1.19L2,18 h20L18.53,10.19z M9.5,16h-1C8.22,16,8,15.78,8,15.5C8,15.22,8.22,15,8.5,15h1c0.28,0,0.5,0.22,0.5,0.5C10,15.78,9.78,16,9.5,16z M9.5,14h-1C8.22,14,8,13.78,8,13.5C8,13.22,8.22,13,8.5,13h1c0.28,0,0.5,0.22,0.5,0.5C10,13.78,9.78,14,9.5,14z M9.5,12h-1 C8.22,12,8,11.78,8,11.5C8,11.22,8.22,11,8.5,11h1c0.28,0,0.5,0.22,0.5,0.5C10,11.78,9.78,12,9.5,12z M12.5,16h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C13,15.78,12.78,16,12.5,16z M12.5,14h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C13,13.78,12.78,14,12.5,14z M12.5,12h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C13,11.78,12.78,12,12.5,12z M15.5,16h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C16,15.78,15.78,16,15.5,16z M15.5,14h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C16,13.78,15.78,14,15.5,14z M15.5,12h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C16,11.78,15.78,12,15.5,12z" />
    </SvgIcon>
  );
}

function InventoryIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M20,2H4C3,2,2,2.9,2,4v3.01C2,7.73,2.43,8.35,3,8.7V20c0,1.1,1.1,2,2,2h14c0.9,0,2-0.9,2-2V8.7c0.57-0.35,1-0.97,1-1.69V4 C22,2.9,21,2,20,2z M19,20H5V9h14V20z M20,7H4V4h16V7z" />
    </SvgIcon>
  );
}

export default function Dashboard() {
  const user = useSelector((state) => state.user.loggedin);
  const myuser = useSelector((state) => state.user.user);

  const classes = useStyles();

  ///my code

  const [openedDropdown, setOpenedDropdown] = React.useState(false);
  const [openedSalesDropdown, setSalesOpenedDropdown] = React.useState(false);
  const [openedInventoryDropdown, setInventoryOpenedDropdown] =
    React.useState(false);

  const handleOpen = () => {
    if (openedDropdown) setOpenedDropdown(false);
    else setOpenedDropdown(true);
  };

  const handleOpenSales = () => {
    if (openedSalesDropdown) setSalesOpenedDropdown(false);
    else setSalesOpenedDropdown(true);
  };

  const handleOpenInventory = () => {
    if (openedInventoryDropdown) setInventoryOpenedDropdown(false);
    else setInventoryOpenedDropdown(true);
  };

  const handleAnalyse = () => {};

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/*  <AppBar
        position="fixed"
        className={classes.appBar}
        style={{ backgroundColor: "white" }}
      >
        <Toolbar>
          <Button className={classes.title}>
            <img src={logo} alt="Logo" width="180" />
          </Button>
          <Typography variant="h6" noWrap>
            Clipped drawer
          </Typography>
        </Toolbar>
      </AppBar> */}
      {/* <Navbar className={classes.appBar} /> */}
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        style={{ backgroundImage: `url(${backgroundImg})` }}
      >
        <Toolbar />
        <div
          style={{ backgroundImage: `url(${backgroundImg})`, height: "100%" }}
        >
          <div className={classes.drawerContainer} style={{ marginTop: "20%" }}>
            <div>
              <p style={{ color: COLORS.white }}>nothingo</p>
              {user ? (
                <p style={{ color: COLORS.white }}>{myuser.email}</p>
              ) : (
                <p style={{ color: COLORS.white }}>nothing</p>
              )}
            </div>
            <div style={{ color: COLORS.white }}>
              {" "}
              <Button
                name="vs"
                size="medium"
                onClick={() => handleAnalyse()}
                style={{ color: "white", textTransform: "none" }}
              >
                <ListItemIcon style={{ color: COLORS.white }}>
                  <PointOfSaleIcon />
                </ListItemIcon>{" "}
                POS
              </Button>
            </div>
            <div style={{ color: COLORS.white }}>
              {" "}
              <Button
                name="vs"
                size="medium"
                onClick={() => handleAnalyse()}
                style={{ color: "white", textTransform: "none" }}
              >
                <ListItemIcon style={{ color: COLORS.white }}>
                  <DashboardIcon />
                </ListItemIcon>{" "}
                Dashboard
              </Button>
            </div>
            <Divider />
            <hr style={{ width: "90%" }}></hr>
            <Button
              name="vs"
              size="medium"
              onClick={() => handleOpen()}
              style={{
                color: "white",
                textTransform: "none",
              }}
            >
              <ListItemIcon style={{ color: COLORS.white }}>
                <InboxIcon />
              </ListItemIcon>{" "}
              Product Manager{" "}
              {!openedDropdown ? <ArrowDropDown /> : <ArrowDropUp />}
            </Button>
            {openedDropdown && (
              <List style={{ color: COLORS.white }}>
                <ListItem button key="View Products">
                  View Products
                </ListItem>
                <ListItem button key="Add Products">
                  Add Products
                </ListItem>
                <ListItem button key="Edit Products">
                  Edit Products
                </ListItem>
              </List>
            )}
            <Button
              name="vs"
              size="medium"
              onClick={() => handleOpenSales()}
              style={{ color: "white", textTransform: "none" }}
            >
              <ListItemIcon style={{ color: COLORS.white }}>
                <ReceiptIcon />
              </ListItemIcon>{" "}
              Sales Manager{" "}
              {!openedDropdown ? <ArrowDropDown /> : <ArrowDropUp />}
            </Button>
            {openedSalesDropdown && (
              <List style={{ color: COLORS.white }}>
                <ListItem button key="View Sales">
                  View Sales
                </ListItem>
                <ListItem button key="Add Sales">
                  Analyse Sales
                </ListItem>
                <ListItem button key="Edit Sales">
                  Edit Sales
                </ListItem>
              </List>
            )}
            <Button
              name="vs"
              size="medium"
              onClick={() => handleOpenInventory()}
              style={{ color: COLORS.white, textTransform: "none" }}
            >
              <ListItemIcon style={{ color: COLORS.white }}>
                <InventoryIcon />
              </ListItemIcon>{" "}
              Inventory Manager{" "}
              {!openedInventoryDropdown ? <ArrowDropDown /> : <ArrowDropUp />}
            </Button>
            {openedInventoryDropdown && (
              <List style={{ color: COLORS.white }}>
                <ListItem button key="View Inventory">
                  View Inventory
                </ListItem>
                <ListItem button key="Add Stock">
                  Add Stock
                </ListItem>
                <ListItem button key="Edit Inventory">
                  Edit Inventory
                </ListItem>
              </List>
            )}
            <Button
              name="vs"
              size="medium"
              onClick={() => handleAnalyse()}
              style={{ color: "white", textTransform: "none" }}
            >
              <ListItemIcon style={{ color: COLORS.white }}>
                <TimelineIcon />
              </ListItemIcon>{" "}
              Chats
            </Button>

            {/*  <List>
            {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <a href="">Product Manager</a>
          <Divider />
          <List>
            {["All mail", "Trash", "Spam"].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List> */}
          </div>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <ProductManager />
        <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Rhoncus
          dolor purus non enim praesent elementum facilisis leo vel. Risus at
          ultrices mi tempus imperdiet. Semper risus in hendrerit gravida rutrum
          quisque non tellus. Convallis convallis tellus id interdum velit
          laoreet id donec ultrices. Odio morbi quis commodo odio aenean sed
          adipiscing. Amet nisl suscipit adipiscing bibendum est ultricies
          integer quis. Cursus euismod quis viverra nibh cras. Metus vulputate
          eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo
          quis imperdiet massa tincidunt. Cras tincidunt lobortis feugiat
          vivamus at augue. At augue eget arcu dictum varius duis at consectetur
          lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa sapien
          faucibus et molestie ac.
        </Typography>
        <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est
          ullamcorper eget nulla facilisi etiam dignissim diam. Pulvinar
          elementum integer enim neque volutpat ac tincidunt. Ornare suspendisse
          sed nisi lacus sed viverra tellus. Purus sit amet volutpat consequat
          mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis
          risus sed vulputate odio. Morbi tincidunt ornare massa eget egestas
          purus viverra accumsan in. In hendrerit gravida rutrum quisque non
          tellus orci ac. Pellentesque nec nam aliquam sem et tortor. Habitant
          morbi tristique senectus et. Adipiscing elit duis tristique
          sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography>
        <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est
          ullamcorper eget nulla facilisi etiam dignissim diam. Pulvinar
          elementum integer enim neque volutpat ac tincidunt. Ornare suspendisse
          sed nisi lacus sed viverra tellus. Purus sit amet volutpat consequat
          mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis
          risus sed vulputate odio. Morbi tincidunt ornare massa eget egestas
          purus viverra accumsan in. In hendrerit gravida rutrum quisque non
          tellus orci ac. Pellentesque nec nam aliquam sem et tortor. Habitant
          morbi tristique senectus et. Adipiscing elit duis tristique
          sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography>
        <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est
          ullamcorper eget nulla facilisi etiam dignissim diam. Pulvinar
          elementum integer enim neque volutpat ac tincidunt. Ornare suspendisse
          sed nisi lacus sed viverra tellus. Purus sit amet volutpat consequat
          mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis
          risus sed vulputate odio. Morbi tincidunt ornare massa eget egestas
          purus viverra accumsan in. In hendrerit gravida rutrum quisque non
          tellus orci ac. Pellentesque nec nam aliquam sem et tortor. Habitant
          morbi tristique senectus et. Adipiscing elit duis tristique
          sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography>
        <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est
          ullamcorper eget nulla facilisi etiam dignissim diam. Pulvinar
          elementum integer enim neque volutpat ac tincidunt. Ornare suspendisse
          sed nisi lacus sed viverra tellus. Purus sit amet volutpat consequat
          mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis
          risus sed vulputate odio. Morbi tincidunt ornare massa eget egestas
          purus viverra accumsan in. In hendrerit gravida rutrum quisque non
          tellus orci ac. Pellentesque nec nam aliquam sem et tortor. Habitant
          morbi tristique senectus et. Adipiscing elit duis tristique
          sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography>
      </main>
    </div>
  );
}
