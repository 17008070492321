import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import {
  ListItem,
  Button,
  Grid,
  Divider,
  ListItemText,
  Typography,
  TextField,
  Paper,
} from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FormControl from "@material-ui/core/FormControl";
import { newCategory } from "../actions/categoryActions";
import { connect, useSelector, useDispatch } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { clearErrors } from "../actions/errorActions";
import { newBrand, fetchBrands, updateBrand } from "../actions/brandActions";
import { fetchProducts } from "../actions/productActions";
import { COLORS } from "./colors";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
    textTransform: "uppercase",
  },
  inlinePadding: {
    display: "inline",
    marginLeft: "3ch",
  },
  margin: {
    margin: theme.spacing(2),
    width: "100px",
    height: "40px",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1, 3, 1, 1),
    width: "400px",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      padding: theme.spacing(1, 1, 1, 1),
    },
  },
}));

export default function UpdateBrandForm(props) {
  const dispatch = useDispatch();
  const [openedForm, setOpenedForm] = React.useState();
  const [myerror, setErrors] = useState("");

  const products = useSelector((state) => state.products.items);
  const categories = useSelector((state) => state.categories.items);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(clearErrors());
      setErrors("");
    }, 5000);
    return () => clearTimeout(timer);
  }, [openedForm]);

  const classes = useStyles();
  const [values, setValues] = React.useState({
    name: props.name,
    price: props.price,
  });

  const handleOpen = () => {
    props.onClose();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      values.name != "" &&
      values.price != "" &&
      category != "" &&
      productX != ""
    ) {
      if (
        values.name != props.name ||
        values.price != props.price ||
        category != props.category ||
        productX != props.product
      ) {
        const brand = {
          product_id: productX,
          name: values.name,
          price: values.price,
        };

        dispatch(updateBrand(props.id, brand));
        props.onClose();

        setValues({
          ...values,
          name: "",
          price: "",
        });
        setProductX("");
        setCategory("");
        setProductList([]);
      } else {
        props.onClose();
      }
    } else {
      setErrors("All fields are required");
    }
  };

  const [productX, setProductX] = useState(props.product);
  const [category, setCategory] = useState(props.category);
  const [productsList, setProductList] = useState(props.prodList);

  const [xRows, setXrows] = useState([]);

  const handleChangeP = (e) => {
    setProductX(e.target.value);
  };
  const handleChangeC = (e) => {
    setCategory(e.target.value);
    console.log("category selected");

    var testRows = xRows;
    const newproducts = products.map((product) => {
      if (product.category.id == e.target.value) {
        testRows = Object.assign([], testRows); //Make a copy of object using the Object.assign method and try again,
        testRows.push(product);
        console.log(product);
      }
    });
    setProductList(testRows); //new products
    console.log(testRows);
  };
  ///const myproduct = products.find((p) => p.name == nameid);

  const errors = useSelector((state) => state.error.errors);
  const success = useSelector((state) => state.error.success);

  return (
    <Paper variant="outlined" className={classes.paper}>
      {/**CREATE FORM TO CAPTURE FAMILY DETAILS */}
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item>
          <Paper elevation={3} className={classes.paper}>
            <form
              className={classes.form}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {myerror && <Alert severity="error">{myerror}</Alert>}

              <div>
                <InputLabel htmlFor="category">Category</InputLabel>
                <Select
                  native
                  value={category}
                  onChange={handleChangeC}
                  inputProps={{
                    name: "category",
                    id: "category",
                  }}
                  style={{ width: "50%" }}
                >
                  <option aria-label="None" value="" />
                  {categories.map((categories) => (
                    <option
                      value={categories.id}
                      style={{ fontSize: "20px", lineHeight: "20px" }}
                    >
                      {categories.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div>
                <InputLabel htmlFor="category" style={{ marginTop: "10px" }}>
                  Product
                </InputLabel>
                <Select
                  native
                  value={productX}
                  onChange={handleChangeP}
                  inputProps={{
                    name: "product",
                    id: "product",
                  }}
                  style={{ width: "50%" }}
                >
                  <option aria-label="None" value="" />
                  {productsList.map((product) => (
                    <option
                      value={product.id}
                      style={{ fontSize: "20px", lineHeight: "20px" }}
                    >
                      {product.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <TextField
                  label="Name"
                  value={values.name}
                  onChange={handleChange("name")}
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label="Price"
                  type="number"
                  value={values.price}
                  onChange={handleChange("price")}
                  fullWidth
                />
              </div>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid item>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    // color={COLORS.primary}
                    className={classes.margin}
                    style={{
                      backgroundColor: COLORS.primary,
                      color: COLORS.white,
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={handleOpen}
                    className={classes.margin}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}
