import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CategoryForm from "./CategoryForm";
import ProductForm from "./ProductForm";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import {
  ListItem,
  Divider,
  ListItemText,
  Typography,
  Paper,
} from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import BrandForm from "./BrandForm";
import AddIcon from "@material-ui/icons/Add";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import { clearErrors } from "../actions/errorActions";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import { connect, useSelector, useDispatch } from "react-redux";
import { COLORS } from "./colors";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    //display: "flex",
    flexGrow: 1,
    height: "80vh",
    width: "100",
    //height: "100%",
    // maxWidth: "1400px",
    backgroundColor: theme.palette.background.paper,
    paddingLeft: "10%",
    paddingRight: "10%",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    //marginRight: "50px",
  },
  inline: {
    display: "inline",
    textTransform: "uppercase",
  },
  inlinePadding: {
    display: "inline",
    marginLeft: "3ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    backgroundColor: COLORS.primary,
    color: COLORS.white,
    height: 80,
    width: 200,
    margin: theme.spacing(1),
    padding: theme.spacing(3, 1, 1, 1),
    textAlign: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 0, 0),
    },
  },
}));

export default function AddProducts(props) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [catForm, setCatForm] = React.useState(false);
  const [branForm, setBranForm] = React.useState(false);
  const [prodForm, setProdForm] = React.useState(false);

  const handleOpen = (e) => {
    if (e == "category") {
      setCatForm(true);
    } else if (e == "product") {
      setProdForm(true);
    } else {
      setBranForm(true);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCatForm(false);
    setProdForm(false);
    setBranForm(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(clearErrors());
    }, 2500);
    return () => clearTimeout(timer);
  }, [open]);

  const classes = useStyles();

  const [count, setCount] = useState(0);
  const [productid, setProductid] = useState("");

  const products = useSelector((state) => state.products.items);
  const errors = useSelector((state) => state.error.errors);
  const success = useSelector((state) => state.error.success);

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", justifyContent: "center", color: "red" }}>
        <Typography variant="h7" component="h7"></Typography>
      </div>
      {errors && <Alert severity="error">{errors.message}</Alert>}
      {success && <Alert severity="success">Successfully added</Alert>}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ flexGrow: 1, display: "flex ", marginTop: "10%" }}
        spacing={5}
      >
        <Grid item>
          <Button
            onClick={() => {
              handleOpen("category");
            }}
          >
            <Card className={classes.paper}>
              <CardActionArea>
                {" "}
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginTop: "5%",
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  New Category
                </div>
              </CardActionArea>
            </Card>
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              handleOpen("product");
            }}
          >
            <Card
              className={classes.paper}
              style={{ backgroundColor: "#558000" }}
            >
              <CardActionArea>
                {" "}
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginTop: "5%",
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  New Product
                </div>
              </CardActionArea>
            </Card>
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              handleOpen("brand");
            }}
          >
            <Card
              className={classes.paper}
              style={{ backgroundColor: "#88cc00" }}
            >
              <CardActionArea>
                {" "}
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginTop: "5%",
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  New Brand
                </div>
              </CardActionArea>
            </Card>
          </Button>
        </Grid>
      </Grid>

      {/* <CategoryForm />
      <ProductForm />
      <Divider variant="middle" /> */}

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper2}>
              <h2 id="transition-modal-title">
                {" "}
                {catForm && <div>Add Category</div>}
                {prodForm && <div>Add Products</div>}
                {branForm && <div>Add Brand</div>}
              </h2>
              <p id="transition-modal-description">
                {catForm && <CategoryForm onClose={handleClose} />}
                {prodForm && <ProductForm onClose={handleClose} />}
                {branForm && <BrandForm onClose={handleClose} />}
              </p>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}
