import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { connect, useSelector, useDispatch } from "react-redux";
import { IconButton, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { makeStyles } from "@material-ui/core/styles";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import { COLORS } from "./colors";
import UpdateCategoryForm from "./UpdateCategoryForm";
import { deleteCategory, fetchCategories } from "../actions/categoryActions";
import Swal from "sweetalert2";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      //display: "flex",
      flexGrow: 1,
      height: "80vh",
      width: "100",
      //height: "100%",
      // maxWidth: "1400px",
      backgroundColor: theme.palette.background.paper,
      paddingLeft: "10%",
      paddingRight: "10%",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "10px",
        paddingRight: "10px",
      },
      //marginRight: "50px",
    },
    inline: {
      display: "inline",
      textTransform: "uppercase",
    },
    inlinePadding: {
      display: "inline",
      marginLeft: "3ch",
    },
    margin: {
      margin: theme.spacing(1),
    },
    form: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
      },
    },
    paper: {
      backgroundColor: COLORS.primary,
      color: COLORS.white,
      height: 80,
      width: 200,
      margin: theme.spacing(1),
      padding: theme.spacing(3, 1, 1, 1),
      textAlign: "center",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper2: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("xs")]: {
        //width: "300px",
        //marginLeft: "50px",
        // marginRight: "50px",
        //  padding: theme.spacing(1, 1, 1, 1),
      },
    },
  }),
  { index: 1 }
);

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

export default function UpdateCategories() {
  ///

  const [catname, setCatName] = React.useState("");
  const [catid, setCatId] = React.useState("");

  const handleClick = (event, cellValues) => {
    //console.log(cellValues.row);
    //console.log(cellValues.row.id);
    setCatId(cellValues.row.id);
    setCatName(cellValues.row.name);
    handleOpen();
  };

  const handleDelete = (event, cellValues) => {
    //console.log(cellValues.row);
    //console.log(cellValues.row.id);
    setCatId(cellValues.row.id);
    setCatName(cellValues.row.name);
    Swal.fire({
      title: "Are you sure you want to delete?",
      text: "This will delete Products and Brands for this category, You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        //delete
        dispatch(deleteCategory(cellValues.row.id)).then((result) => {
          //  console.log(result);
          if (result == "success") {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          } else {
            Swal.fire("An error occured!", "An error occured.", "error");
          }
        });
      }
    });
  };

  const columns = [
    /*    { field: "id", headerName: "ID", width: 100 }, */

    {
      field: "name",
      headerName: "Category Name",
      width: 300,
    },
    {
      field: "Edit",
      renderCell: (cellValues) => {
        return (
          <IconButton
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
            style={{ color: "green" }}
          >
            <EditIcon />
          </IconButton>
        );
      },
      width: 150,
    },
    {
      field: "Delete",
      renderCell: (cellValues) => {
        return (
          <IconButton
            onClick={(event) => {
              handleDelete(event, cellValues);
            }}
            style={{ color: "red" }}
          >
            <DeleteForeverIcon />
          </IconButton>
        );
      },
      width: 150,
    },
  ];

  const rows = [
    { id: 1, name: "tafe" },
    { id: 2, name: "tafe2" },
    { id: 3, name: "tafe3" },
    { id: 4, name: "tafe4" },
  ];

  ////

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const products = useSelector((state) => state.products.items);
  const items = useSelector((state) => state.brands.items);
  const catitems = useSelector((state) => state.categories.items);
  const catitem = useSelector((state) => state.categories.item);

  useEffect(() => {
    return () => dispatch(fetchCategories());
  }, [catitem]);

  function getCategory(id) {
    const name = catitems.map((item) => {
      //   console.log(id);
      console.log(item.id);
      if (item.id == id) return item.name;
    });
    //    console.log(name);
    const found = name.find((element) => element !== undefined); // get only defined element
    return found;
  }

  const [myRows, setMyRows] = React.useState(items);
  const [xRows, setXRows] = React.useState([]);

  var testRows = xRows;
  /*products.map((product) => {
    testRows.push({
      productCode: product.product_code,
      productName: product.product_code,
      brand: product.product_code,
      unitCost: product.product_code,
    });
  });
  setMyRows(testRows) */

  const testButton = () => {};

  //   () => {
  const newState = catitems.map((category) => {
    const obj = {
      id: category.id,
      name: category.name,
    };
    //console.log(obj);
    /*  testRows = Object.assign([], testRows); //Make a copy of object using the Object.assign method and try again,
    testRows.push(obj); */
    testRows = [...testRows, obj];
  });

  //console.log(testRows);
  //setXRows(testRows);
  //setMyRows({ ...xRows, newState });
  //   };

  return (
    <div style={{ height: 500 }}>
      <div style={{ display: "flex", justifyContent: "center", color: "red" }}>
        <Typography variant="h5" component="h5">
          Categories
        </Typography>
      </div>
      <br></br>
      <DataGrid
        rows={testRows}
        columns={columns}
        // pageSize={10}
        //checkboxSelection
        disableSelectionOnClick
        rowsPerPageOptions={[]}
      />

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                }}
              >
                <Typography variant="h5" component="h5">
                  Update Category
                </Typography>
              </div>
              <p id="transition-modal-description">
                <UpdateCategoryForm
                  name={catname}
                  id={catid}
                  onClose={handleClose}
                />
              </p>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}
