import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { connect, useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

const columns = [
  {
    field: "category",
    headerName: "Category",
    width: 200,
    editable: true,
  },
  { field: "productCode", headerName: "Product Code", width: 200 },
  {
    field: "productName",
    headerName: "Product Name",
    width: 300,
    editable: true,
  },
  {
    field: "brand",
    headerName: "Brand/System",
    width: 200,
    editable: true,
  },
  {
    field: "unitCost",
    headerName: "Unit Cost",
    type: "number",
    width: 200,
    editable: true,
  },
];

export default function ViewProducts() {
  const items = useSelector((state) => state.brands.items);
  const catitems = useSelector((state) => state.categories.items);

  function getCategory(id) {
    const name = catitems.map((item) => {
      if (item.id == id) return item.name;
    });

    const found = name.find((element) => element !== undefined); // get only defined element
    return found;
  }

  const [myRows, setMyRows] = React.useState(items);
  const [xRows, setXRows] = React.useState([]);

  let testRows = xRows;

  const newState = items.map((product) => {
    const obj = {
      id: product.id,
      category: getCategory(product.product.category_id),
      productCode: product.product.product_code,
      productName: product.product.name,
      brand: product.name,
      unitCost: "$ " + ccyFormat(product.price),
    };
    testRows = [...testRows, obj];
  });

  return (
    <div style={{ height: 500, width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "center", color: "red" }}>
        <Typography variant="h5" component="h5">
          Product List
        </Typography>
      </div>
      <br></br>
      <DataGrid
        rows={testRows}
        columns={columns}
        //pageSize={10}
        checkboxSelection
        disableSelectionOnClick
        rowsPerPageOptions={[]}
      />
    </div>
  );
}
