import React from "react";
import LoginForm from "./LoginForm";
import { Grid } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../assets/img/mandulologo.png";

function LoginPage() {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      width: "100%",
      minHeight: "100vh",
      //backgroundColor: "yellow",
    },
    formContainer: {
      marginTop: "0%",
    },
    title: {
      fontSize: "30px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
      fontWeight: "bold",
      marginTop: "50px",
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.root} minHeight="100%">
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.formContainer}
        >
          <Grid item sm={4} md={6}>
            <Typography className={classes.title} align="center">
              <img src={logo} alt="Logo" width="180" />
            </Typography>
            <LoginForm />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default LoginPage;
