import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Admin from "./components/Admin";
import { Grid } from "@material-ui/core";
import POS from "./components/POS";
import { BrowserRouter } from "react-router-dom";
import { Route } from "react-router-dom";
import Inventory from "./components/Inventory";
import ProductManager from "./components/ProductManager";
import { Provider, useSelector } from "react-redux";
import store from "./store";
import LoginPage from "./components/LoginPage";
import { createBrowserHistory } from "history";
import { setuser } from "./actions/userActions";
import LoginForm from "./components/LoginForm.js";
import ViewPrices from "./components/ViewPrices";
import AddDamagedStock from "./components/AddDamagedStock";
import SalesManager from "./components/SalesManager";
import InventoryManager from "./components/IventoryManager";
import Analyse from "./components/Analyse";
import Dashboard from "./components/TestDrawer";
import ThisDrawer from "./components/ThisDrawer";
import Home from "./components/Home";

function App() {
  const user = useSelector((state) => state.user.loggedin);
  const myuser = useSelector((state) => state.user.user);

  useEffect(() => {
    /* const access = localStorage.getItem('access_token')
    if (access) {
      store.dispatch(setuser(access))
    } */
  }, []);

  const myhistory = createBrowserHistory();

  return (
    <React.Fragment>{user ? <Home /> : <LoginPage />}</React.Fragment>
    //    </Provider>
  );
}

export default App;
