import React, { useState, useEffect } from "react";
import Navbar from "./NavBar.js";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AddStock from "./AddStock.js";
import ViewInventory from "./ViewInventory.js";
import { useHistory } from "react-router-dom";
import { fetchProducts } from "../actions/productActions";
import { connect, useSelector, useDispatch } from "react-redux";
import { fetchBrands } from "../actions/brandActions";
import { fetchStocks } from "../actions/stockActions";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import NavButtons from "./NavButtons";

import { COLORS } from "./colors";

export default function Inventory() {
  const useStyles = makeStyles((theme) => ({
    root: {
      // display: "flex",
      width: "100",
      // maxWidth: "1400px",
      backgroundColor: theme.palette.background.paper,
      paddingLeft: "10%",
      paddingRight: "10%",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
  }));

  const classes = useStyles();

  const dispatch = useDispatch();

  const [state, setState] = useState({
    view: "0",
  });

  useEffect(() => {
    dispatch(fetchStocks());
    dispatch(fetchBrands());
  }, [state.view]);

  const handleViews = (event) => {
    console.log(state.view);
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.name,
    });
  };
  const handleAddView = () => {
    setState({
      ...state,
      ["view"]: 1,
    });
  };

  const handleInventoryView = () => {
    dispatch(fetchProducts);

    setState({
      ...state,
      ["view"]: 2,
    });
  };

  let history = useHistory();
  function handlePClick() {
    history.push("/productmanager");
  }
  return (
    <div className={classes.root}>
      <NavButtons view="2" />
      <div style={{ display: "flex", justifyContent: "center", color: "red" }}>
        <Typography variant="h5" component="h5">
          Inventory
        </Typography>
      </div>

      <br></br>
      <ViewInventory />
    </div>
  );
}
