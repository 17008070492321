import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CategoryForm from "./CategoryForm";
import ProductForm from "./ProductForm";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import {
  ListItem,
  Divider,
  ListItemText,
  Typography,
  Paper,
} from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import BrandForm from "./BrandForm";
import AddIcon from "@material-ui/icons/Add";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import { clearErrors } from "../actions/errorActions";
import { Hidden } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import { connect, useSelector, useDispatch } from "react-redux";
import { COLORS } from "./colors";
import Alert from "@material-ui/lab/Alert";
import UpdateCategories from "./UpdateCategories";
import UpdateProducts from "./UpdateProducts";
import UpdateBrands from "./UpdateBrands";

const useStyles = makeStyles((theme) => ({
  root: {
    //display: "flex",
    flexGrow: 1,
    height: "80vh",
    width: "100%",
    //height: "100%",
    // maxWidth: "1400px",
    backgroundColor: theme.palette.background.default,
    //paddingLeft: "15px",
    //  paddingRight: "15px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    //marginRight: "50px",
  },
  inline: {
    display: "inline",
    textTransform: "uppercase",
  },
  inlinePadding: {
    display: "inline",
    marginLeft: "3ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    backgroundColor: COLORS.primary,
    color: COLORS.white,
    height: 50,
    width: 140,
    margin: theme.spacing(1),
    padding: theme.spacing(2, 1, 1, 1),
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      height: 40,
      width: 90,
      padding: theme.spacing(1, 1, 1, 1),
      margin: theme.spacing(0),
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  topButtops: {
    width: "100%",
    height: "100%",
    fontWeight: "bold",
    fontSize: "15px",
    //  color: "#1f1f2e",
    //marginTop: "5%",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));

export default function EditProducts(props) {
  const dispatch = useDispatch();

  const catitem = useSelector((state) => state.categories.item);

  const [open, setOpen] = React.useState(false);
  const [catForm, setCatForm] = React.useState(false);
  const [branForm, setBranForm] = React.useState(false);
  const [prodForm, setProdForm] = React.useState(false);

  const handleOpen = (e) => {
    if (e == "category") {
      setCatForm(true);
      setProdForm(false);
      setBranForm(false);
    } else if (e == "product") {
      setProdForm(true);
      setCatForm(false);
      setBranForm(false);
    } else {
      setBranForm(true);
      setCatForm(false);
      setProdForm(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCatForm(false);
    setProdForm(false);
    setBranForm(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(clearErrors());
    }, 2500);
    return () => clearTimeout(timer);
  }, [open]);

  const classes = useStyles();

  const [count, setCount] = useState(0);
  const [productid, setProductid] = useState("");

  const products = useSelector((state) => state.products.items);
  const errors = useSelector((state) => state.error.errors);
  const success = useSelector((state) => state.error.success);

  let button1Style = { backgroundColor: COLORS.disabled, color: COLORS.black };
  let button2Style = { backgroundColor: COLORS.disabled, color: COLORS.black };
  let button3Style = { backgroundColor: COLORS.disabled, color: COLORS.black };

  if (catForm) {
    button1Style = { backgroundColor: COLORS.primary, color: COLORS.white };
  }
  if (prodForm) {
    button2Style = { backgroundColor: COLORS.primary };
  }
  if (branForm) {
    button3Style = { backgroundColor: COLORS.primary };
  }

  return (
    <div className={classes.root}>
      <div
        style={{
          flexGrow: 1,
          //padding: "20px",
        }}
      >
        <Grid container direction="row" spacing={0} justify="center">
          <Grid item>
            <Button
              onClick={() => {
                handleOpen("category");
              }}
            >
              <Card className={classes.paper} style={button1Style}>
                <CardActionArea>
                  {" "}
                  <div className={classes.topButtops}>Update Category</div>
                </CardActionArea>
              </Card>
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                handleOpen("product");
              }}
            >
              <Card className={classes.paper} style={button2Style}>
                <CardActionArea>
                  {" "}
                  <div className={classes.topButtops}>Update Product</div>
                </CardActionArea>
              </Card>
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                handleOpen("brand");
              }}
            >
              <Card className={classes.paper} style={button3Style}>
                <CardActionArea>
                  {" "}
                  <div className={classes.topButtops}>Update Brand</div>
                </CardActionArea>
              </Card>
            </Button>
          </Grid>
        </Grid>
      </div>
      <hr></hr>

      {/* Bottom */}
      <div>
        {errors && <Alert severity="error">{errors.message}</Alert>}
        {success && <Alert severity="success">Successfully added</Alert>}
        {!catForm && !prodForm && !branForm && (
          <div
            style={{ display: "flex", justifyContent: "center", color: "red" }}
          >
            <Hidden smDown>
              <Typography variant="h5" component="h5">
                Use Buttons Above to Select Item to Update
              </Typography>
            </Hidden>
            <Hidden smUp>
              <Typography variant="h8" component="h8">
                Use Buttons Above to Select Item to Update
              </Typography>
            </Hidden>
          </div>
        )}
        {catForm && <UpdateCategories />}
        {prodForm && <UpdateProducts />}
        {branForm && <UpdateBrands />}
      </div>
    </div>
  );
}
