import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { newProduct } from "../actions/productActions";
import { connect, useSelector, useDispatch } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { clearErrors } from "../actions/errorActions";
import Paper from "@material-ui/core/Paper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { COLORS } from "./colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
    textTransform: "uppercase",
  },
  inlinePadding: {
    display: "inline",
    marginLeft: "3ch",
  },
  margin: {
    margin: theme.spacing(2),
    width: "100px",
    height: "40px",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    width: "100%",
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1, 3, 1, 1),
    width: "400px",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      padding: theme.spacing(1, 1, 1, 1),
    },
  },
}));

export default function ProductForm(props) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [name, setName] = useState("");
  const [product_code, setProductCode] = useState("");
  const [category, setCategory] = useState("");

  const categories = useSelector((state) => state.categories.items);

  const handleChange = (e) => {
    setName(e.target.value);
  };
  const [myerror, setErrors] = useState("");

  const handleChangeC = (e) => {
    setCategory(e.target.value);
  };

  const handleChangeP = (e) => {
    setProductCode(e.target.value);
  };

  const [openedForm, setOpenedForm] = React.useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(clearErrors());
      setErrors("");
    }, 5000);
    return () => clearTimeout(timer);
  }, [openedForm]);

  const handleOpen = () => {
    props.onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name != "" && category != "" && product_code != "") {
      const product = {
        product_code: product_code,
        name: name,
        category: category,
      };
      dispatch(newProduct(product));
      props.onClose();
    } else {
      setErrors("All fields required");
    }
  };

  const errors = useSelector((state) => state.error.errors);
  const success = useSelector((state) => state.error.success);
  return (
    <div>
      <br />

      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item>
          <Paper elevation={3} className={classes.paper}>
            <form
              className={classes.form}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {myerror && <Alert severity="error">{myerror}</Alert>}
              {/*    <Typography
                style={{ textAlign: "center" }}
                variant="overline"
                display="block"
                gutterBottom
              >
                New Product
              </Typography> */}

              <div>
                <InputLabel htmlFor="category">Category</InputLabel>
                <Select
                  native
                  value={category}
                  onChange={handleChangeC}
                  inputProps={{
                    name: "category",
                    id: "category",
                  }}
                  style={{ width: "50%" }}
                >
                  <option aria-label="None" value="" />
                  {categories.map((category) => (
                    <option
                      value={category.id}
                      style={{ fontSize: "20px", lineHeight: "20px" }}
                    >
                      {category.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <TextField
                  label="Product Code"
                  value={product_code}
                  onChange={handleChangeP}
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label="Name"
                  value={name}
                  onChange={handleChange}
                  fullWidth
                />
              </div>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid item>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    // color={COLORS.primary}
                    className={classes.margin}
                    style={{
                      backgroundColor: COLORS.primary,
                      color: COLORS.white,
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={handleOpen}
                    className={classes.margin}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
