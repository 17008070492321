import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CategoryForm from "./CategoryForm";
import ProductForm from "./ProductForm";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import {
  ListItem,
  Divider,
  ListItemText,
  Typography,
  Paper,
} from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import BrandForm from "./BrandForm";
import AddIcon from "@material-ui/icons/Add";

import AddProducts from "./AddProducts";

import { connect, useSelector, useDispatch } from "react-redux";
import ViewProducts from "./ViewProducts";
import EditProducts from "./EditProducts";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    // maxWidth: "1400px",
    // backgroundColor: "cyan", //theme.palette.background.paper,
    //paddingLeft: "5px",
    //paddingRight: "5px",
    /* [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    }, */
    //marginRight: "50px",
  },
  inline: {
    display: "inline",
    textTransform: "uppercase",
  },
  inlinePadding: {
    display: "inline",
    marginLeft: "3ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1, 3, 1, 1),
  },
}));

export default function ProductManager(props) {
  const { id } = props.match.params;

  const classes = useStyles();

  const [count, setCount] = useState(0);
  const [productid, setProductid] = useState("");

  const products = useSelector((state) => state.products.items);

  return (
    <div className={classes.root}>
      {id == "view" && <ViewProducts />}
      {id == "add" && <AddProducts />}
      {id == "edit" && <EditProducts />}
    </div>
  );
}
