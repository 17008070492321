import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";

import { COLORS } from "./colors";
import Button from "@material-ui/core/Button";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import backgroundImg from "../assets/img/bg2.jpg";
import { Provider, useSelector, useDispatch } from "react-redux";
import PointOfSale from "../assets/img/point_of_sale.svg";
import TimelineIcon from "@material-ui/icons/Timeline";
import ReceiptIcon from "@material-ui/icons/Receipt";
//import InventoryIcon from "@mui/icons-material/Inventory";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { Hidden, IconButton, SvgIcon } from "@material-ui/core";

import logo from "../assets/img/mandulologo2.png";
import MenuIcon from "@material-ui/icons/Menu";

import SearchBar from "material-ui-search-bar";
import GridItem from "./components/Grid/GridItem";
import GridContainer from "./components/Grid/GridContainer";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

////

import "../App.css";
import Admin from "./Admin";
import POS from "./POS";
import { BrowserRouter, NavLink } from "react-router-dom";
import { Route } from "react-router-dom";
import Inventory from "./Inventory";
import ProductManager from "./ProductManager";
import store from "../store";
import LoginPage from "./LoginPage";
import { createBrowserHistory } from "history";
import { setuser } from "../actions/userActions";
import LoginForm from "./LoginForm.js";
import ViewPrices from "./ViewPrices";
import SalesManager from "./SalesManager";
import InventoryManager from "./IventoryManager";
import Analyse from "./Analyse";
import Dashboard from "./Dashboard";
import ThisDrawer from "./ThisDrawer";
import { logout } from "../actions/userActions";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#f0f5f5",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "1px",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    width: "100%",
    //theme.palette.background.default,
    padding: theme.spacing(1),
  },
  myToolBar: {
    flex: "1",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
  },
  list: {
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit",
    lineHeight: "4.5em",
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    /* [theme.breakpoints.down("sm")]: {
          width: "100%",
          "&:after": {
            width: "calc(100% - 30px)",
            content: '""',
            display: "block",
            height: "1px",
            marginLeft: "15px",
            backgroundColor: "#e5e5e5"
          }
        } */
  },
}));

function PointOfSaleIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M17,2H7C5.9,2,5,2.9,5,4v2c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V4C19,2.9,18.1,2,17,2z M17,6H7V4h10V6z M20,22H4 c-1.1,0-2-0.9-2-2v-1h20v1C22,21.1,21.1,22,20,22z M18.53,10.19C18.21,9.47,17.49,9,16.7,9H7.3c-0.79,0-1.51,0.47-1.83,1.19L2,18 h20L18.53,10.19z M9.5,16h-1C8.22,16,8,15.78,8,15.5C8,15.22,8.22,15,8.5,15h1c0.28,0,0.5,0.22,0.5,0.5C10,15.78,9.78,16,9.5,16z M9.5,14h-1C8.22,14,8,13.78,8,13.5C8,13.22,8.22,13,8.5,13h1c0.28,0,0.5,0.22,0.5,0.5C10,13.78,9.78,14,9.5,14z M9.5,12h-1 C8.22,12,8,11.78,8,11.5C8,11.22,8.22,11,8.5,11h1c0.28,0,0.5,0.22,0.5,0.5C10,11.78,9.78,12,9.5,12z M12.5,16h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C13,15.78,12.78,16,12.5,16z M12.5,14h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C13,13.78,12.78,14,12.5,14z M12.5,12h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C13,11.78,12.78,12,12.5,12z M15.5,16h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C16,15.78,15.78,16,15.5,16z M15.5,14h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C16,13.78,15.78,14,15.5,14z M15.5,12h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C16,11.78,15.78,12,15.5,12z" />
    </SvgIcon>
  );
}

function InventoryIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M20,2H4C3,2,2,2.9,2,4v3.01C2,7.73,2.43,8.35,3,8.7V20c0,1.1,1.1,2,2,2h14c0.9,0,2-0.9,2-2V8.7c0.57-0.35,1-0.97,1-1.69V4 C22,2.9,21,2,20,2z M19,20H5V9h14V20z M20,7H4V4h16V7z" />
    </SvgIcon>
  );
}

export default function Home(props) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.loggedin);
  const myuser = useSelector((state) => state.user.user);

  const classes = useStyles();

  const [openedDropdown, setOpenedDropdown] = React.useState(false);
  const [openedSalesDropdown, setSalesOpenedDropdown] = React.useState(false);
  const [openedInventoryDropdown, setInventoryOpenedDropdown] =
    React.useState(false);

  const handleOpen = () => {
    if (openedDropdown) setOpenedDropdown(false);
    else setOpenedDropdown(true);
  };

  const handleOpenSales = () => {
    if (openedSalesDropdown) setSalesOpenedDropdown(false);
    else setSalesOpenedDropdown(true);
  };

  const handleOpenInventory = () => {
    if (openedInventoryDropdown) setInventoryOpenedDropdown(false);
    else setInventoryOpenedDropdown(true);
  };

  const handleAnalyse = () => {};

  //for mobile drawer

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleLogout = () => {
    dispatch(
      logout(() => {
        props.history.push("/");
      })
    );
    //setAnchorEl(null);
  };

  const drawerComponent = (
    <div
      //onClick={toggleDrawer("left", false)}
      onKeyDown={toggleDrawer("left", false)}
      style={{ backgroundImage: `url(${backgroundImg})`, height: "100vh" }}
    >
      <div>
        <div style={{ marginTop: 10 }}>
          <Button className={classes.title}>
            <NavLink
              to="/"
              color="inherit"
              onClick={toggleDrawer("left", false)}
            >
              <img src={logo} alt="Logo" width="180" />
            </NavLink>
          </Button>
        </div>
        <div className={classes.drawerContainer} style={{ marginTop: "30%" }}>
          <div>
            {/*   <p style={{ color: COLORS.white }}>nothingo</p> */}
            {user ? (
              <p
                style={{
                  color: "#b3ffb3",
                  fontSize: "24px",
                  marginLeft: "10px",
                }}
              >
                Hi {myuser.user.name}!
              </p>
            ) : (
              <p style={{ color: COLORS.white }}>nothing</p>
            )}
          </div>
          <div style={{ color: COLORS.white }}>
            {" "}
            <Button
              name="vs"
              size="medium"
              style={{ color: "white", textTransform: "none" }}
            >
              <ListItemIcon style={{ color: COLORS.white }}>
                <PointOfSaleIcon />
              </ListItemIcon>{" "}
              <NavLink
                style={{
                  color: COLORS.white,
                  textTransform: "none !important",
                  textDecoration: "none",
                  fontWeight: "normal",
                }}
                to="/"
                color="inherit"
                onClick={toggleDrawer("left", false)}
              >
                POS
              </NavLink>
            </Button>
          </div>
          <div style={{ color: COLORS.white }}>
            {" "}
            <Button
              name="vs"
              size="medium"
              onClick={() => handleAnalyse()}
              style={{ color: "white", textTransform: "none" }}
            >
              <ListItemIcon style={{ color: COLORS.white }}>
                <DashboardIcon />
              </ListItemIcon>{" "}
              <NavLink
                style={{
                  color: COLORS.white,
                  textTransform: "none !important",
                  textDecoration: "none",
                  fontWeight: "normal",
                }}
                to="/dashboard"
                color="inherit"
                onClick={toggleDrawer("left", false)}
              >
                Dashboard
              </NavLink>
            </Button>
          </div>
          <Divider />
          <hr style={{ width: "90%" }}></hr>
          <Button
            name="vs"
            size="medium"
            onClick={() => handleOpen()}
            style={{
              color: "white",
              textTransform: "none",
            }}
          >
            <ListItemIcon style={{ color: COLORS.white }}>
              <InboxIcon />
            </ListItemIcon>{" "}
            Product Manager{" "}
            {!openedDropdown ? <ArrowDropDown /> : <ArrowDropUp />}
          </Button>
          {openedDropdown && (
            <List style={{ color: COLORS.white }}>
              <ListItem button key="View Products">
                <NavLink
                  style={{
                    color: COLORS.white,
                    textTransform: "none !important",
                    textDecoration: "none",
                    fontWeight: "normal",
                  }}
                  to="/productmanager/view"
                  color="inherit"
                  onClick={toggleDrawer("left", false)}
                >
                  View Products
                </NavLink>
              </ListItem>
              <ListItem button key="Add Products">
                <NavLink
                  style={{
                    color: COLORS.white,
                    textTransform: "none !important",
                    textDecoration: "none",
                    fontWeight: "normal",
                  }}
                  to="/productmanager/add"
                  color="inherit"
                  onClick={toggleDrawer("left", false)}
                >
                  Add Products
                </NavLink>
              </ListItem>
              <ListItem button key="Edit Products">
                <NavLink
                  style={{
                    color: COLORS.white,
                    textTransform: "none !important",
                    textDecoration: "none",
                    fontWeight: "normal",
                  }}
                  to="/productmanager/edit"
                  color="inherit"
                  onClick={toggleDrawer("left", false)}
                >
                  Edit Products
                </NavLink>
              </ListItem>
            </List>
          )}
          <Button
            name="vs"
            size="medium"
            onClick={() => handleOpenSales()}
            style={{ color: "white", textTransform: "none" }}
          >
            <ListItemIcon style={{ color: COLORS.white }}>
              <ReceiptIcon />
            </ListItemIcon>{" "}
            Sales Manager{" "}
            {!openedDropdown ? <ArrowDropDown /> : <ArrowDropUp />}
          </Button>
          {openedSalesDropdown && (
            <List style={{ color: COLORS.white }}>
              <ListItem button key="View Sales">
                <NavLink
                  style={{
                    color: COLORS.white,
                    textTransform: "none !important",
                    textDecoration: "none",
                    fontWeight: "normal",
                  }}
                  to="/managesales/view"
                  color="inherit"
                  onClick={toggleDrawer("left", false)}
                >
                  View Sales
                </NavLink>
              </ListItem>
              <ListItem button key="Add Sales">
                <NavLink
                  style={{
                    color: COLORS.white,
                    textTransform: "none !important",
                    textDecoration: "none",
                    fontWeight: "normal",
                  }}
                  to="/managesales/analyse"
                  color="inherit"
                  onClick={toggleDrawer("left", false)}
                >
                  Analyse Sales
                </NavLink>
              </ListItem>
              <ListItem button key="Edit Sales">
                <NavLink
                  style={{
                    color: COLORS.white,
                    textTransform: "none !important",
                    textDecoration: "none",
                    fontWeight: "normal",
                  }}
                  to="/managesales/edit"
                  color="inherit"
                  onClick={toggleDrawer("left", false)}
                >
                  Edit Sales
                </NavLink>
              </ListItem>
            </List>
          )}
          <Button
            name="vs"
            size="medium"
            onClick={() => handleOpenInventory()}
            style={{ color: COLORS.white, textTransform: "none" }}
          >
            <ListItemIcon style={{ color: COLORS.white }}>
              <InventoryIcon />
            </ListItemIcon>{" "}
            Inventory Manager{" "}
            {!openedInventoryDropdown ? <ArrowDropDown /> : <ArrowDropUp />}
          </Button>
          {openedInventoryDropdown && (
            <List style={{ color: COLORS.white }}>
              <ListItem button key="View Inventory">
                <NavLink
                  style={{
                    color: COLORS.white,
                    textTransform: "none !important",
                    textDecoration: "none",
                    fontWeight: "normal",
                  }}
                  to="/inventorymanager"
                  color="inherit"
                  onClick={toggleDrawer("left", false)}
                >
                  View Inventory
                </NavLink>
              </ListItem>
              <ListItem button key="Add Stock">
                <NavLink
                  style={{
                    color: COLORS.white,
                    textTransform: "none !important",
                    textDecoration: "none",
                    fontWeight: "normal",
                  }}
                  to="/inventorymanager"
                  color="inherit"
                  onClick={toggleDrawer("left", false)}
                >
                  Add Stock
                </NavLink>{" "}
              </ListItem>
              <ListItem button key="Edit Inventory">
                <NavLink
                  style={{
                    color: COLORS.white,
                    textTransform: "none !important",
                    textDecoration: "none",
                    fontWeight: "normal",
                  }}
                  to="/inventorymanager"
                  color="inherit"
                  onClick={toggleDrawer("left", false)}
                >
                  Edit Inventory
                </NavLink>
              </ListItem>
            </List>
          )}
          <Button
            name="vs"
            size="medium"
            onClick={() => handleAnalyse()}
            style={{ color: "white", textTransform: "none" }}
          >
            <ListItemIcon style={{ color: COLORS.white }}>
              <TimelineIcon />
            </ListItemIcon>{" "}
            <NavLink
              style={{
                color: COLORS.white,
                textTransform: "none !important",
                textDecoration: "none",
                fontWeight: "normal",
              }}
              to="/analyse"
              color="inherit"
              onClick={toggleDrawer("left", false)}
            >
              Chats
            </NavLink>
          </Button>

          <br></br>
          <Button
            name="vs"
            size="medium"
            onClick={() => handleLogout()}
            style={{
              color: "white",
              textTransform: "none",
              marginTop: "50px",
            }}
          >
            <ListItemIcon style={{ color: COLORS.white }}>
              <AccountCircleIcon />
            </ListItemIcon>{" "}
            <NavLink
              style={{
                color: COLORS.white,
                textTransform: "none !important",
                textDecoration: "none",
                fontWeight: "normal",
              }}
              to="/"
              color="inherit"
            >
              Logout
            </NavLink>
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={1}>
        <Toolbar className={classes.myToolBar}>
          <IconButton
            style={{ color: COLORS.primary }}
            onClick={toggleDrawer("left", true)}
          >
            <MenuIcon />
          </IconButton>
          <Hidden smDown>
            <Typography variant="h6" noWrap style={{ color: COLORS.primary }}>
              Mandulo
            </Typography>
          </Hidden>

          <Hidden smUp>
            <div>
              <Button className={classes.title}>
                <img src={logo} alt="Logo" width="160" />
              </Button>
            </div>
          </Hidden>

          <div style={{ width: "60%" }}></div>

          <Hidden smDown>
            <SearchBar
            // value={this.state.value}
            //onChange={(newValue) => this.setState({ value: newValue })}
            //  onRequestSearch={() => doSomethingWith(this.state.value)}
            />
          </Hidden>

          <IconButton>
            <AccountCircleIcon />
          </IconButton>
          {/* 
          <List className={classes.list}>
            <ListItem className={classes.list}>
              <SearchBar
              // value={this.state.value}
              //onChange={(newValue) => this.setState({ value: newValue })}
              //  onRequestSearch={() => doSomethingWith(this.state.value)}
              />
            </ListItem>
            <List>
              <IconButton>
                <AccountCircleIcon />
              </IconButton>
            </List>
          </List> */}
        </Toolbar>
      </AppBar>

      <Hidden smDown>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
          style={{ backgroundImage: `url(${backgroundImg})` }}
        >
          <Divider />

          <div
            style={{ backgroundImage: `url(${backgroundImg})`, height: "100%" }}
          >
            <div style={{ marginTop: 10 }}>
              <Button className={classes.title}>
                <NavLink
                  to="/"
                  color="inherit"
                  onClick={toggleDrawer("left", false)}
                >
                  <img src={logo} alt="Logo" width="180" />
                </NavLink>
              </Button>
            </div>
            <div
              className={classes.drawerContainer}
              style={{ marginTop: "30%" }}
            >
              <div>
                {/*   <p style={{ color: COLORS.white }}>nothingo</p> */}
                {user ? (
                  <p
                    style={{
                      color: "#b3ffb3",
                      fontSize: "24px",
                      marginLeft: "10px",
                    }}
                  >
                    Hi {myuser.user.name}!
                  </p>
                ) : (
                  <p style={{ color: COLORS.white }}>nothing</p>
                )}
              </div>
              <div style={{ color: COLORS.white }}>
                {" "}
                <Button
                  name="vs"
                  size="medium"
                  style={{ color: "white", textTransform: "none" }}
                >
                  <ListItemIcon style={{ color: COLORS.white }}>
                    <PointOfSaleIcon />
                  </ListItemIcon>{" "}
                  <NavLink
                    style={{
                      color: COLORS.white,
                      textTransform: "none !important",
                      textDecoration: "none",
                      fontWeight: "normal",
                    }}
                    to="/"
                    color="inherit"
                  >
                    POS
                  </NavLink>
                </Button>
              </div>
              <div style={{ color: COLORS.white }}>
                {" "}
                <Button
                  name="vs"
                  size="medium"
                  onClick={() => handleAnalyse()}
                  style={{ color: "white", textTransform: "none" }}
                >
                  <ListItemIcon style={{ color: COLORS.white }}>
                    <DashboardIcon />
                  </ListItemIcon>{" "}
                  <NavLink
                    style={{
                      color: COLORS.white,
                      textTransform: "none !important",
                      textDecoration: "none",
                      fontWeight: "normal",
                    }}
                    to="/dashboard"
                    color="inherit"
                  >
                    Dashboard
                  </NavLink>
                </Button>
              </div>
              <Divider />
              <hr style={{ width: "90%" }}></hr>
              <Button
                name="vs"
                size="medium"
                onClick={() => handleOpen()}
                style={{
                  color: "white",
                  textTransform: "none",
                }}
              >
                <ListItemIcon style={{ color: COLORS.white }}>
                  <InboxIcon />
                </ListItemIcon>{" "}
                Product Manager{" "}
                {!openedDropdown ? <ArrowDropDown /> : <ArrowDropUp />}
              </Button>
              {openedDropdown && (
                <List style={{ color: COLORS.white }}>
                  <ListItem button key="View Products">
                    <NavLink
                      style={{
                        color: COLORS.white,
                        textTransform: "none !important",
                        textDecoration: "none",
                        fontWeight: "normal",
                      }}
                      to="/productmanager/view"
                      color="inherit"
                    >
                      View Products
                    </NavLink>
                  </ListItem>
                  <ListItem button key="Add Products">
                    <NavLink
                      style={{
                        color: COLORS.white,
                        textTransform: "none !important",
                        textDecoration: "none",
                        fontWeight: "normal",
                      }}
                      to="/productmanager/add"
                      color="inherit"
                    >
                      Add Products
                    </NavLink>
                  </ListItem>
                  <ListItem button key="Edit Products">
                    <NavLink
                      style={{
                        color: COLORS.white,
                        textTransform: "none !important",
                        textDecoration: "none",
                        fontWeight: "normal",
                      }}
                      to="/productmanager/edit"
                      color="inherit"
                    >
                      Edit Products
                    </NavLink>
                  </ListItem>
                </List>
              )}
              <Button
                name="vs"
                size="medium"
                onClick={() => handleOpenSales()}
                style={{ color: "white", textTransform: "none" }}
              >
                <ListItemIcon style={{ color: COLORS.white }}>
                  <ReceiptIcon />
                </ListItemIcon>{" "}
                Sales Manager{" "}
                {!openedDropdown ? <ArrowDropDown /> : <ArrowDropUp />}
              </Button>
              {openedSalesDropdown && (
                <List style={{ color: COLORS.white }}>
                  <ListItem button key="View Sales">
                    <NavLink
                      style={{
                        color: COLORS.white,
                        textTransform: "none !important",
                        textDecoration: "none",
                        fontWeight: "normal",
                      }}
                      to="/managesales/view"
                      color="inherit"
                    >
                      View Sales
                    </NavLink>
                  </ListItem>
                  <ListItem button key="Add Sales">
                    <NavLink
                      style={{
                        color: COLORS.white,
                        textTransform: "none !important",
                        textDecoration: "none",
                        fontWeight: "normal",
                      }}
                      to="/managesales/analyse"
                      color="inherit"
                    >
                      analyse Sales
                    </NavLink>
                  </ListItem>
                  <ListItem button key="Edit Sales">
                    <NavLink
                      style={{
                        color: COLORS.white,
                        textTransform: "none !important",
                        textDecoration: "none",
                        fontWeight: "normal",
                      }}
                      to="/managesales/edit"
                      color="inherit"
                    >
                      Edit Sales
                    </NavLink>
                  </ListItem>
                </List>
              )}
              <Button
                name="vs"
                size="medium"
                onClick={() => handleOpenInventory()}
                style={{ color: COLORS.white, textTransform: "none" }}
              >
                <ListItemIcon style={{ color: COLORS.white }}>
                  <InventoryIcon />
                </ListItemIcon>{" "}
                Inventory Manager{" "}
                {!openedInventoryDropdown ? <ArrowDropDown /> : <ArrowDropUp />}
              </Button>
              {openedInventoryDropdown && (
                <List style={{ color: COLORS.white }}>
                  <ListItem button key="View Inventory">
                    <NavLink
                      style={{
                        color: COLORS.white,
                        textTransform: "none !important",
                        textDecoration: "none",
                        fontWeight: "normal",
                      }}
                      to="/inventorymanager"
                      color="inherit"
                    >
                      View Inventory
                    </NavLink>
                  </ListItem>
                  <ListItem button key="Add Stock">
                    <NavLink
                      style={{
                        color: COLORS.white,
                        textTransform: "none !important",
                        textDecoration: "none",
                        fontWeight: "normal",
                      }}
                      to="/inventorymanager"
                      color="inherit"
                    >
                      Add Stock
                    </NavLink>{" "}
                  </ListItem>
                  <ListItem button key="Edit Inventory">
                    <NavLink
                      style={{
                        color: COLORS.white,
                        textTransform: "none !important",
                        textDecoration: "none",
                        fontWeight: "normal",
                      }}
                      to="/inventorymanager"
                      color="inherit"
                    >
                      Edit Inventory
                    </NavLink>
                  </ListItem>
                </List>
              )}
              <Button
                name="vs"
                size="medium"
                onClick={() => handleAnalyse()}
                style={{ color: "white", textTransform: "none" }}
              >
                <ListItemIcon style={{ color: COLORS.white }}>
                  <TimelineIcon />
                </ListItemIcon>{" "}
                <NavLink
                  style={{
                    color: COLORS.white,
                    textTransform: "none !important",
                    textDecoration: "none",
                    fontWeight: "normal",
                  }}
                  to="/analyse"
                  color="inherit"
                >
                  Chats
                </NavLink>
              </Button>
              <br></br>
              <Button
                name="vs"
                size="medium"
                onClick={() => handleLogout()}
                style={{
                  color: "white",
                  textTransform: "none",
                  marginTop: "50px",
                }}
              >
                <ListItemIcon style={{ color: COLORS.white }}>
                  <AccountCircleIcon />
                </ListItemIcon>{" "}
                <NavLink
                  style={{
                    color: COLORS.white,
                    textTransform: "none !important",
                    textDecoration: "none",
                    fontWeight: "normal",
                  }}
                  to="/"
                  color="inherit"
                >
                  Logout
                </NavLink>
              </Button>
            </div>
          </div>
        </Drawer>
      </Hidden>

      {/* drawer for mobile*/}

      <div>
        <React.Fragment>
          <Drawer
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
            //  style={{ backgroundImage: `url(${backgroundImg})` }}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {/* {list(anchor)} */}
            {drawerComponent}
          </Drawer>
        </React.Fragment>
      </div>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Route
          path="/"
          exact
          render={(props) => {
            return (
              <div>
                {user ? (
                  <POS user={myuser.user.id} />
                ) : (
                  <React.Fragment>
                    <LoginPage />
                  </React.Fragment>
                )}
              </div>
            );
          }}
        />
        {/*  <Route path="/login" exact component={LoginPage} /> */}
        <Route path="/inventory" component={Inventory} />
        <Route path="/prices" component={ViewPrices} />
        <Route
          path="/productmanager/:id"
          render={(props) => <ProductManager {...props} />}
        />
        <Route path="/inventorymanager/:id" component={InventoryManager} />
        <Route path="/managesales/:id" component={SalesManager} />
        <Route path="/analyse" component={Analyse} />
        <Route path="/dashboard" component={Dashboard} />
      </main>
    </div>
  );
}
