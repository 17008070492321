import React, { useEffect, useState } from "react";
import Navbar from "./NavBar.js";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { fetchProducts } from "../actions/productActions";
import { fetchCategories } from "../actions/categoryActions";
import { connect, useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { useHistory } from "react-router-dom";
import { fetchBrands } from "../actions/brandActions.js";
import { COLORS } from "./colors";

export default function Admin({ view }) {
  const [cview, setCview] = useState(1);
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    button: {
      width: "400px",
    },
  }));

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchProducts());
    dispatch(fetchBrands());
  }, []);

  const classes = useStyles;
  let history = useHistory();
  function handleIClick() {
    setCview(2);
    history.push("/inventory");
  }
  function handleSClick() {
    setCview(1);
    history.push("/");
  }
  function handlePClick() {
    setCview(3);
    history.push("/prices");
  }

  return (
    <div>
      <Router />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item>
          {view == 1 ? (
            <Button
              variant="contained"
              style={{ backgroundColor: COLORS.primary, color: COLORS.white }}
              onClick={handleSClick}
              className={classes.button}
            >
              Sale
            </Button>
          ) : (
            <Button
              variant="contained"
              style={{ backgroundColor: COLORS.disabled }}
              onClick={handleSClick}
              className={classes.button}
            >
              Sale
            </Button>
          )}
        </Grid>
        <Grid item>
          {view == 2 ? (
            <Button
              variant="contained"
              style={{ backgroundColor: COLORS.primary, color: COLORS.white }}
              onClick={handleIClick}
            >
              Inventory
            </Button>
          ) : (
            <Button
              variant="contained"
              style={{ backgroundColor: COLORS.disabled }}
              onClick={handleIClick}
            >
              Inventory
            </Button>
          )}
        </Grid>
        <Grid item>
          {view == 3 ? (
            <Button
              variant="contained"
              style={{ backgroundColor: COLORS.primary, color: COLORS.white }}
              onClick={handlePClick}
            >
              Prices
            </Button>
          ) : (
            <Button
              variant="contained"
              style={{ backgroundColor: COLORS.disabled }}
              onClick={handlePClick}
            >
              Prices
            </Button>
          )}
        </Grid>
      </Grid>
      <hr></hr>
    </div>
  );
}
